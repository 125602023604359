export function getUseCases() {
  return [
    { id: "marketers", name: "Marketer" },
    { id: "product-owners", name: "Product Owner" },
    { id: "agencies", name: "Agency" },
    { id: "content-creators", name: "Content Creator" },
    { id: "influencers", name: "Influencer" },
    { id: "publishers", name: "Publisher" },
    { id: "politics", name: "Political Campaign" },
    { id: "nonprofits", name: "Non-Profit" },
  ];
}

export function shouldUseAn(useCase) {
  if (!useCase) return false;
  const whitelist = ["agencies", "influencers"];
  return whitelist.includes(useCase.id);
}
