import React from "react";
import { Popover, Position, Menu, MenuItem, Icon } from "@blueprintjs/core";
import classes from "./UseCaseSwitchSection.module.css";

export default function UseCaseSwitch({ options, value, onChange }) {
  return (
    <Popover position={Position.TOP}>
      <div className={classes.dropDown}>
        {!value && <p>Please Select</p>}
        {value && <p>{value.name}</p>}
        <Icon icon="chevron-down" color="#02a3a4" iconSize={24} />
      </div>
      <Menu large>
        {options.map(o => (
          <MenuItem key={o.id} text={o.name} onClick={() => onChange(o)} />
        ))}
      </Menu>
    </Popover>
  );
}
