import React from "react";
import UseCasePage from "../components/UseCasePage/UseCasePage";
import { graphql } from "gatsby";

function UseCasePageTemplate({ data }) {
  return <UseCasePage data={data} />;
}

export const query = graphql`
  query($slug: String!) {
    allContentfulUseCasePage(filter: { slug: { eq: $slug } }) {
      ...UseCasePageData
    }
  }
`;

export default UseCasePageTemplate;
