import React from "react";
import Page from "../Page/Page";
import FeatureSection from "../../sections/FeatureSection/FeatureSection";
import TestimonialSection from "../../sections/TestimonialSection/TestimonialSection";
import MediumPostSection from "../../sections/MediumPostSection/MediumPostSection";
import BrandPartnershipSection from "../../sections/BrandPartnershipSection/BrandPartnershipSection";
import ColumnSection from "../../sections/ColumnSection/ColumnSection";
import SidekickSection from "../../sections/SidekickSection/SidekickSection";
import AudienceSection from "../../sections/AudienceSection/AudienceSection";
import UseCaseSwitchSection from "../UseCaseSwitchSection/UseCaseSwitchSection";
import UseCaseHeroSection from "../../sections/UseCaseHeroSection/UseCaseHeroSection";
import ContentSection from "../../sections/ContentSection/ContentSection";
import { graphql } from "gatsby";

function UseCasePage({ title, data, location }) {
  const pageNode = data.allContentfulUseCasePage.edges[0].node;

  const {
    heroTitle,
    heroSubtitle,
    heroZiplineVideo,
    heroImage,
    seoTitle,
    seoDescription,
    seoImage,
    hideSwitcher,
  } = pageNode;

  const mapContentSection = (contentSection, index) => {
    switch (contentSection.__typename) {
      case "ContentfulContentSection":
        return (
          <ContentSection
            key={`${contentSection.__typename}-${index}`}
            content={contentSection.content}
          />
        );

      case "ContentfulFeatureSection":
        return (
          <FeatureSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            features={contentSection.features}
          />
        );

      case "ContentfulTestimonialSection":
        return (
          <TestimonialSection
            key={`${contentSection.__typename}-${index}`}
            author={contentSection.author.author}
            testimonial={contentSection.testimonial.content[0].content[0].value}
            image={contentSection.image.file.url}
          />
        );

      case "ContentfulMediumSection":
        return (
          <MediumPostSection
            key={`${contentSection.__typename}-${index}`}
            showAreYouReadyAllReady={contentSection.showAreYouReadyAllReady}
          />
        );

      case "ContentfulSidekickSection":
        return (
          <SidekickSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            subtitle={
              contentSection.subtitle && contentSection.subtitle.subtitle
            }
          />
        );

      case "ContentfulLogoSection":
        return (
          <BrandPartnershipSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            brands={contentSection.brands}
          />
        );

      case "ContentfulColumnSection":
        return (
          <ColumnSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            content={contentSection.content.content}
            image={contentSection.image && contentSection.image.file.url}
            phoneScreenImage={
              contentSection.phoneScreenImage &&
              contentSection.phoneScreenImage.file.url
            }
            callToActions={contentSection.callToActions}
            darkened={contentSection.darkened}
            imageSide={contentSection.imageSide}
            showRequestDemoForm={contentSection.showRequestDemoForm}
            videoUrl={contentSection.videoUrl}
            verticallyCentered={contentSection.verticallyCentered}
          />
        );

      case "ContentfulAudienceSection":
        return (
          <AudienceSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            userGroups={contentSection.userGroups}
          />
        );

      default:
        throw new Error(`Invalid section type: ${contentSection.__typename}`);
    }
  };

  return (
    <Page
      title={seoTitle}
      description={seoDescription && seoDescription.seoDescription}
      image={seoImage && seoImage.file.url}
    >
      <UseCaseHeroSection
        title={heroTitle}
        subtitle={heroSubtitle.heroSubtitle}
        ziplineVideo={heroZiplineVideo}
        heroImage={heroImage}
      />

      {pageNode.contentSections &&
        pageNode.contentSections.map(mapContentSection)}

      {!hideSwitcher && <UseCaseSwitchSection />}
    </Page>
  );
}

export const useCasePageFragment = graphql`
  fragment UseCasePageData on ContentfulUseCasePageConnection {
    edges {
      node {
        contentSections {
          __typename
          ... on ContentfulContentSection {
            internal {
              type
            }
            content {
              content
            }
          }
          ... on ContentfulFeatureSection {
            title
            features {
              title
              icon {
                file {
                  url
                }
              }
              description {
                description
              }
              callToAction {
                label
                link
              }
            }
            internal {
              type
            }
          }
          ... on ContentfulLogoSection {
            title
            brands {
              brandName
              logo {
                file {
                  url
                }
              }
            }
            internal {
              type
            }
          }
          ... on ContentfulMediumSection {
            internal {
              type
            }
            showAreYouReadyAllReady
          }
          # ... on Node {
          #   ... on ContentfulTestimonialSection {
          #     id
          #     image {
          #       file {
          #         url
          #       }
          #     }
          #     testimonial {
          #       content {
          #         content {
          #           value
          #         }
          #       }
          #     }
          #     author {
          #       author
          #     }
          #   }
          # }
          ... on ContentfulColumnSection {
            id
            darkened
            imageSide
            showRequestDemoForm
            title
            videoUrl
            verticallyCentered
            content {
              content
            }
            image {
              file {
                url
              }
            }
            # phoneScreenImage {
            #   file {
            #     url
            #   }
            # }
            callToActions {
              ... on ContentfulRequestDemoCallToAction {
                id
                placeholder
                buttonLabel
                section
                intercomTags
                internal {
                  type
                }
              }
              ... on ContentfulButtonCallToAction {
                id
                label
                link
                internal {
                  type
                }
              }
            }
          }
          ... on ContentfulSidekickSection {
            id
            title
            subtitle {
              subtitle
            }
          }
          # ... on ContentfulAudienceSection {
          #   id
          #   title
          #   userGroups {
          #     link
          #     name
          #     icon {
          #       file {
          #         url
          #       }
          #     }
          #   }
          # }
        }
        heroTitle
        heroSubtitle {
          heroSubtitle
        }
        heroZiplineVideo
        heroImage {
          file {
            url
          }
        }
        seoTitle
        seoDescription {
          seoDescription
        }
        hideSwitcher
        # seoImage {
        #   file {
        #     url
        #   }
        # }
      }
    }
  }
`;

export default UseCasePage;
