import React from "react";
import marked from "marked";
import classes from "./UseCaseHeroSection.module.css";

function UseCaseHeroSection({ title, subtitle, ziplineVideo, heroImage }) {
  return (
    <div className={classes.useCaseHero}>
      <div className={classes.top}>
        <div className={classes.wrapper}>
          <h1>{title}</h1>
        </div>
      </div>

      <div className={classes.bottom}>
        <div className={classes.wrapper}>
          <div className={classes.screen}>
            {ziplineVideo && (
              <iframe
                src={ziplineVideo}
                frameBorder={0}
                allow="encrypted-media"
                allowFullScreen
              ></iframe>
            )}
            {heroImage && <img src={heroImage.file.url} alt="" />}
          </div>
          <h2 dangerouslySetInnerHTML={{ __html: marked(subtitle) }} />
        </div>
      </div>
    </div>
  );
}

export default UseCaseHeroSection;
